/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { } from 'react';
import { Container, Modal } from 'reactstrap';
import { Spinner } from '../../../../AbstractElements';
import PdfViewer from './PdFViewer';
import { X } from 'react-feather';

function MobilePdfModal({ open, setOpen, fileLoading, selectedFileContent }) {;
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        size='xl'
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <Container fluid={true} style={{ padding: '30px', textAlign: 'center' }} >
          <button className="imgButton" style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={handleClose} ><X width={20}/></button>
          {fileLoading ? (
            <div className="loader-box">
              <Spinner attrSpinner={{ className: 'loader-1' }} />
            </div>
          ) : (
            <>
              <h4>File scelto:</h4>
              {selectedFileContent && (
                <PdfViewer selectedFileContent={selectedFileContent} />
              )}
            </>
          )}
        </Container>
      </Modal>
    </>
  );
}

export default MobilePdfModal;
