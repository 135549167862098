import React, { Fragment, useEffect } from 'react';
// import { Form, FormGroup, Input, Label } from 'reactstrap';
// import { Btn, H4, P } from '../../../AbstractElements';
// import { EmailAddress, ForgotPassword, LoginWithJWT, Password, RememberPassword, SignIn } from '../../../Constant';
// import { toast } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';
// import { firebase_app, Jwt_token } from '../../../Config/Config';
import { useStateContext } from '../../../contexts/ContextProvider';
// import man from '../../../assets/images/dashboard/1.png';
// import { handleResponse } from '../../../Services/fack.backend';
import Login from './Login'
// import { fetchLogin, validateToken } from '../../../api/api';
import AlertC from '../../../Components/Alerts/AlertC';

function containsError(item) {
    return Object.prototype.hasOwnProperty.call(item, 'error');
}

const LoginTab = () => {
    const { alertOn, setAlertOn, loginResp, setLoginResp } = useStateContext();
    const isLoggedIn = localStorage.getItem('logged');

    // close login alert
    function closeAlertL() {
        setLoginResp({});
        setAlertOn(false);
    }

    const loginErr = containsError(loginResp) && loginResp.error_description !== "Please reset your password!" && loginResp.error_description !== "Your password has expired.  Please reset it.";
    useEffect(() => {
      if (loginErr) {
        setAlertOn(true);
      }
    }, [loginErr, loginResp.error_description, setAlertOn]);
  
    if (isLoggedIn) {
      return (
        <div className="generalDiv dark:text-gray-200">
          <h1>You are already logged in</h1>
        </div>
      );
    }

    return (
        <Fragment>
            {loginErr && (<><AlertC loginA alertOn={alertOn} close={closeAlertL} object={loginResp} msg="" /><br /><br /></>)}
            <Login />
        </Fragment>
    );
};

export default LoginTab;