/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState, useEffect, Fragment } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../contexts/ContextProvider';
import { getGuestsFn, updateGuestFn } from '../../../api/api';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { FormGroup, Label, Container, Modal } from 'reactstrap';
import { Edit } from 'react-feather';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}

const colors = [
  '#FF0000', // Red
  '#00FF00', // Green
  '#006aff', // Blue
  '#FFFF00', // Yellow
  '#FF00FF', // Magenta
  '#00FFFF', // Cyan
  '#FFA500', // Orange
  '#a54aff', // Purple
  '#996932', // Brown
  '#1a8a50'  // Dark green
];

function UpdateGuest({ oldGuest }) {
  const { basictoaster, primary, setGuests, setLoading, setExecuting } = useStateContext();
  // init form data
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    birth_date: '', 
    gender: '',
    colour: '',
    physician: '',
  });
  const [errors, setErrors] = useState({});
  // open
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setFormData({
      name: oldGuest.name,
      surname: oldGuest.surname,
      birth_date: oldGuest.birth_date, 
      gender: oldGuest.gender ? oldGuest.gender : '',
      colour: oldGuest.colour ? oldGuest.colour : '',
      physician: oldGuest.physician ? oldGuest.physician : '',
    })
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // validate from callback
  const validateForm = useCallback(() => {
    const { name, surname, birth_date, gender } = formData;
    const newErrors = {};
    
    if (!name) {
      newErrors.name = 'Il nome è richiesto';
    }
    if (!surname) {
      newErrors.surname = 'Il conome è richiesto';
    }
    if (!birth_date) {
      newErrors.birth_date = 'La data di nascita è richiesto';
    }
    if (!gender) {
      newErrors.gender = 'Il genere è richiesto';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  // validate form if input changes
  useEffect(() =>  {
    validateForm();
  }, [formData, validateForm])

  const updateGuestHandler = () => {
    SweetAlert.fire({
        title: 'Sei sicuro?',
        text: 'Il tuo ospite verrà modificato',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            const guestObj = {
              id: oldGuest.id,
              room: oldGuest.room,
              name: formData.name,
              surname: formData.surname,
              birth_date: formData.birth_date, 
              gender: formData.gender,
              colour: formData.colour,
              physician: formData.physician,
            };
            // console.log(connAgreement)
            await updateGuestFn(guestObj).then(async (response) => {
              getGuestsFn().then((newsResp) => {
                setGuests(newsResp);
                setLoading(false);
              }).catch((err) => {basictoaster("error", "Errore ospiti", err, () => {})});
              if (containsSuccess(response)) {
                  SweetAlert.fire(
                      'Fatto!',
                      'Il tuo ospite è stato modificato.',
                      'success'
                  );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    'Errore!',
                    `Errore: ${response.Error}.`,
                    'error'
                );
              }
              setExecuting(false);
              setOpen(false);
            }).catch((err) => {basictoaster("error", 'Errore modifica ospiti', err, () => {})});
          }
        });
  };

  // submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      updateGuestHandler();
    }
  };

  return (
    <>
      <button className='imgButton' onClick={handleOpen}><Edit width={20} /></button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <Container fluid={true} style={{ padding: '20px' }}>
          <h5>Modifica ospite:</h5><br />
          <form style={{ margin: '10%', marginTop: '1%', marginBottom: '1%' }} onSubmit={handleSubmit} >
            {/* ROOM */}
            <FormGroup>
              <Label htmlFor="room" className="col-form-label pt-0">Stanza</Label>
              <input
                className="form-control"
                type="text"
                id="room"
                name="room"
                disabled
                defaultValue={oldGuest.room}
              />
            </FormGroup>
            {/* NAME */}
            <FormGroup>
              <Label htmlFor="name" className="col-form-label pt-0">Nome</Label>
              <input
                className="form-control"
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <div style={{ color: 'red' }}>{errors.name}</div>}
            </FormGroup>
            {/* SURNAME */}
            <FormGroup>
              <Label htmlFor="surname" className="col-form-label pt-0">Cognome</Label>
              <input
                className="form-control"
                type="text"
                id="surname"
                name="surname"
                value={formData.surname}
                onChange={handleChange}
              />
              {errors.surname && <div style={{ color: 'red' }}>{errors.surname}</div>}
            </FormGroup>
            {/* BIRTH DATE */}
            <FormGroup>
              <Label htmlFor="birth_date" className="col-form-label pt-0">Data di nascita</Label>
              <input
                className="form-control"
                type="date"
                id="birth_date"
                name="birth_date"
                value={formData.birth_date}
                onChange={handleChange}
              />
              {errors.birth_date && <div style={{ color: 'red' }}>{errors.birth_date}</div>}
            </FormGroup>
            {/* GENDER */}
            <FormGroup>
              <Label htmlFor="gender" className="col-form-label pt-0">Genere</Label>
              <input
                className="form-control"
                type="text"
                id="gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              />
              {errors.gender && <div style={{ color: 'red' }}>{errors.gender}</div>}
            </FormGroup>
            {/* COLOUR */}
            <FormGroup>
              <Label htmlFor="colour" className="col-form-label pt-0">Colore</Label>
              <div style={{ display: 'flex', marginBottom: '20px' }}>
                {colors.map((color, index) => (
                  <div
                    key={index}
                    onClick={() => handleChange({ target: { name: 'colour', value: color} })}
                    style={{
                      backgroundColor: color,
                      width: '50px',
                      height: '50px',
                      margin: '5px',
                      cursor: 'pointer',
                      border: formData.colour === color ? '3px solid black' : '1px solid black'
                    }}
                  ></div>
                ))}
              </div>
              {errors.colour && <div style={{ color: 'red' }}>{errors.colour}</div>}
            </FormGroup>
            {/* PHYSICIAN */}
            <FormGroup>
              <Label htmlFor="physician" className="col-form-label pt-0">Medico</Label>
              <input
                className="form-control"
                type="text"
                id="physician"
                name="physician"
                value={formData.physician}
                onChange={handleChange}
              />
              {errors.physician && <div style={{ color: 'red' }}>{errors.physician}</div>}
            </FormGroup>
            <br />
            {/* BUTTONS */}
            <Button
              variant="contained"
              type="submit"
              style={{ backgroundColor: primary }}
            >
              Modifica
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={handleClose}
              style={{ backgroundColor: 'gray', marginLeft: '1%' }}
            >
              Annulla
            </Button>
          </form>
        </Container>
      </Modal>
    </>
  );
}

export default UpdateGuest;
