/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useStateContext } from '../../../contexts/ContextProvider';
import '../../../App.css'
import { Spinner } from '../../../AbstractElements';
import Grow from '@mui/material/Grow';
import { getDocuments, getDocumentContent } from '../../../api/api';
import FileTreeDocuments from './FileTreeDocuments';
import PdFViewer from './PDFViewer/PdFViewer';
import { useMediaQuery } from 'react-responsive'
import MobilePdfModal from './PDFViewer/MobilePdfModal';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

const Documents = () => {
  const { basictoaster, loading, setLoading, documents, setDocuments } = useStateContext();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileContent, setSelectedFileContent] = useState(null);
  const [getContentLoading, setGetContentLoading] = useState(false);
  const [mobilePdfModalOpen, setMobilePdfModalOpen] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const handleFileSelect = (fileItem) => {
    // if the selected item is a file then set the selectedFile state
    if (fileItem.fileType === 'file') {
      setSelectedFile(fileItem);
      // get the b64 content of the selected file calling the api sending in the selected id
      setGetContentLoading(true);
      getDocumentContent(fileItem.id.replace('FILE-', ''), abortController.signal).then((resp) => {
        if (!abortController.signal.aborted) {
          setSelectedFileContent(resp);
          setGetContentLoading(false);
        }
      }).catch((err) => {basictoaster("error", "Errore documenti", err, () => {})});
      // after setting the selected file check if is from mobile and if is open the pdfviewer modal
      setMobilePdfModalOpen(true);
    }
  };

  useEffect(() => {
    // set ab to state and use this new ab in this code
    let newAbortController = new AbortController()
    setAbortController(newAbortController);
    setLoading(true);
    // get the last 8 days firefighters shifts
    getDocuments(newAbortController.signal).then((resp) => {
      if (!newAbortController.signal.aborted) {
        setDocuments(resp);
        setLoading(false);
      }
    }).catch((err) => {basictoaster("error", "Errore documenti", err, () => {})});
    
    // Cleanup function to abort the request when the component unmounts
    return () => {
      newAbortController.abort();
    };
  }, [])

  // ALERT
  useEffect(() => {
    if (isObject(documents) && containsError(documents)) {
      basictoaster("error", "Errore documenti", documents.Error, () => {})
    }
  }, [documents])

  return (
    <Fragment>
    <br />
      <Breadcrumbs phish mainTitle="Documenti" parent="DomusVestra" title="Documenti" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Grow in={true}><div>
            <Card>
              <CardBody>
                {/* <NewReq /><br /><br /> */}
                {loading ? (
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-1' }} />
                  </div>
                ) : (
                  <>
                    <h2><b>Documenti</b></h2><br />
                    {documents.length > 0 ? (
                      // IF IS MOBILE SHOW ONLY FILE TREE AND MOBILE MODAL
                      isTabletOrMobile ? (
                        <>
                          {/* DOCUMENTS TREEVIWER */}
                          <FileTreeDocuments items={documents[0].children}  onSelectFile={handleFileSelect} />
                          {/* MODAL THAT OPENS ONLY IF A FILE IS SELECTED FROM MOBILE */}
                          <MobilePdfModal open={mobilePdfModalOpen} setOpen={setMobilePdfModalOpen} fileLoading={getContentLoading} selectedFileContent={selectedFileContent} />
                        </> 
                      ) : (
                        <Row>
                          <Col sm="4">
                            {/* DOCUMENTS TREEVIWER */}
                            <FileTreeDocuments items={documents[0].children}  onSelectFile={handleFileSelect} />
                          </Col>
                          <Col sm="8">
                            {selectedFile ? (
                              <div>
                                {/* PDF VIEWER */}
                                {getContentLoading ? (
                                  <div className="loader-box">
                                    <Spinner attrSpinner={{ className: 'loader-1' }} />
                                  </div>
                                ) : (
                                  <PdFViewer selectedFileContent={selectedFileContent} />
                                )}
                              </div>
                            ) : (
                              <h4>Nessun file selezionato</h4>
                            )}
                          </Col>
                        </Row>
                      )
                    ) : (
                      <p>Nessun documento trovato ...</p>
                    )}
                  </>
                )}
                {/* <PdFViewer /> */}
              </CardBody>
            </Card>
            </div></Grow>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Documents;