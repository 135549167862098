import React from "react";
import { Modal } from 'reactstrap';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

export default function CircleLoadModal({ executing, closeExecuting }) {
  return (
    <Modal
      isOpen={executing}
      toggle={closeExecuting}
      centered
      backdropClassName={executing ? 'BDModalOpen' : 'BDModalClosed'}
      modalClassName={executing ? 'ModalOpen' : 'ModalClosed'}
      contentClassName="loadingModal"
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    </Modal>
  )
}
