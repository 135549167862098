import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import PdfViewer from '../../Documents/PDFViewer/PdFViewer';

const isAPdf = (attachment) => {
  let attachmentName = attachment.name;
  // if ends with png,jpg,jpeg,webp is an image
  if (attachmentName.endsWith('pdf')) {
    return true
  }
  return false
}

const filterAllPdfsAttachments = (attachmentsArr) => {
  // remove all the attachments that aren't images
  return attachmentsArr.filter(isAPdf)
}

const NewsPdfsCarousel = ({ attachments }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    return () => {
      setActiveIndex(0);
    };
  }, []);

  const onChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <Carousel
      selectedItem={activeIndex}
      onChange={onChange}
      showThumbs={false}
      showStatus={false}
      showArrows={true}
      useKeyboardArrows
      autoPlay={false}
    >
      {filterAllPdfsAttachments(attachments).map((pdfAttachment, index) => (
        <div key={index} style={{ textAlign: 'center', width: '70%', margin: 'auto', marginBottom: '7%' }}>
          <PdfViewer selectedFileContent={{ b64: pdfAttachment.content }} />
        </div>
      ))}
    </Carousel>
  )
}

export default NewsPdfsCarousel