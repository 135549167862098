/* eslint-disable react-hooks/exhaustive-deps */
import React, { } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import '../../../App.css'
import { DataGrid } from '@mui/x-data-grid';
import UpdateUsers from './UpdateUsers';
import ChangeUserPass from './ChangeUserPass';
import DeleteUser from './DeleteUser';

const UsersManagementTable = () => {
  const { users } = useStateContext();
  
  const columns = [
    {
      field: 'username',
      headerName: 'Nome',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'roles',
      headerName: 'Ruoli',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center', fontSize: '90%' }}>
          {params.value && (
            JSON.parse(params.value).join(' | ')
          )}
        </div>
      ),
    },
    {
      field: 'changepass',
      headerName: 'Cambia password',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center', fontSize: '90%' }}>
          <ChangeUserPass usrId={params.row.id} />
        </div>
      ),
    },
    {
      field: 'update',
      headerName: '',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          <UpdateUsers oldUser={params.row} />
        </div>
      ),
    },
    {
      field: 'delete',
      headerName: '',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          <DeleteUser usrId={params.row.id} />
        </div>
      ),
    },
  ];

  return (
    <>
      {users.length > 0 ? (
        <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
          <div style={{ width: '100%' }} className='scrollbar-data-grid'>
            <DataGrid
              rows={users}
              columns={columns}
              getRowId={(row) => row.id}
              initialState={{
                pagination: {
                  // if is from home set the defualt pagesize to 5 so it's shorter otherwise set it to 10
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10, 15]}
              rowHeight={80}
              rowSelection={false}
            />
          </div>
        </div>
      ) : (
        <p>Nessun utente trovato...</p>
      )}
    </>
  );
};

export default UsersManagementTable;