import React from 'react';

const ColoredSquare = ({ bgColor, dimension }) => {
  const squareStyle = {
    width: dimension,
    height: dimension,
    backgroundColor: bgColor,
    display: 'inline-block',
    border: '1px solid black'
  };

  return <div style={squareStyle}></div>;
};

export default ColoredSquare