/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import '../../../App.css'
import { Table } from 'reactstrap';

const transformTimeString = (timeString) => {
  // Function to insert colon in time string
  const insertColon = (time) => time.slice(0, 2) + ':' + time.slice(2);

  // Split the input string into two parts
  const [startTime, endTime] = timeString.split('-');

  // Transform each part
  const transformedStartTime = insertColon(startTime);
  const transformedEndTime = insertColon(endTime);

  // Combine the transformed parts
  return `${transformedStartTime} - ${transformedEndTime}`;
};

const ShiftsTable = ({ shiftsObj, fromHome }) => {
  const { fireFightersShifts, todayDate } = useStateContext();
  const [parsedShifts, setParsedShifts] = useState(null);
  const [parseError, setParseError] = useState(null);

  useEffect(() => {
    // reset parseError
    setParseError(null);
    // Parse the JSON data when the component mounts
    try {
      const parsed = JSON.parse(shiftsObj.shifts);
      setParsedShifts(parsed);
    } catch (error) {
      setParseError(error);
    }
  }, [fireFightersShifts]); 

  return (
    <>
      {parseError ? (
        <p>Errore nella deserializzazione dei turni: {parseError.message}</p>
      ) : (
        shiftsObj.shifts.length > 0 && (
          <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
            <Table style={{ textAlign: 'center' }}>
              <thead className="table-primary table-responsive">
                <tr>
                  {/* map every fascia to a table header */}
                  {parsedShifts && parsedShifts.map((fascia, index) => (
                    <th key={index}>{transformTimeString(fascia.intervallo)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {/* map every fascia to a table column mapping all the dipendenti inside of the column */}
                  {parsedShifts && parsedShifts.map((fascia, index) => (
                    // if is not from home and today date is the same as shift date backround color green otherwise red
                    <td key={index} className={!fromHome ? (todayDate() === shiftsObj.shifts_date ? 'todayShift' : 'otherDaysShifts') : ''} >
                      {fascia.dipendenti.map((dipendente, index) => (
                        <Fragment key={index}>
                          <hr />
                          <b>{dipendente.nominativo}</b><br />
                          {dipendente.presenza && <>Presenza:   {dipendente.presenza}<br /></>}
                          {dipendente.capo && <>Capo<br /></>}
                          {dipendente.squadra && <>{dipendente.squadra}<br /></>}
                          <hr />
                        </Fragment>
                      ))}
                    </td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </div>
        )
      )}
    </>
  );
};

export default ShiftsTable;