/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import '../../../App.css'
import ShiftsTable from './ShiftsTable';
import ViewEmergencyPlan from './ViewEmergencyPlan';

const FireFightersShiftsTable = ({ fromHome, kioskMode }) => {
  const { fireFightersShifts, getWeekdayName, convertDateYearAtEnd } = useStateContext();

  return (
    <>
      {fromHome && <h4>Turni antincendio:</h4>}
      {fireFightersShifts.length > 0 ? (
          fireFightersShifts.map((shiftsObj) => (
            <Fragment key={shiftsObj.id}>
              {/* IF IS NOT IK KIOSK MODE SHOW THE SHIFTS DATE HERE */}
              {!kioskMode && <h5 style={{ textAlign: 'center' }} >{getWeekdayName(shiftsObj.shifts_date)} {convertDateYearAtEnd(shiftsObj.shifts_date)}</h5>}
              <ShiftsTable shiftsObj={shiftsObj} fromHome={fromHome} />
              <br /><br />
            </Fragment>
          ))
        ) : (
          <p>Nessun turno antincendio trovato  {fromHome && 'per oggi '}...</p>
        )}
        {!kioskMode && <ViewEmergencyPlan />}
    </>
  );
};

export default FireFightersShiftsTable;