/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import { useStateContext } from '../../../contexts/ContextProvider';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Grid from '@mui/material/Grid';

function containsB64(item) {
  return Object.prototype.hasOwnProperty.call(item, 'b64');
}
function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}

function base64ToUint8Array(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

const PdfViewer = ({ selectedFileContent }) => {
  const [uint8Array, setUint8Array] = useState(null);
  // every time selected file content changes re do the conversion and checks
  useEffect(() => {
    if (containsB64(selectedFileContent)) {
      try {
        const convertedUint8 = base64ToUint8Array(selectedFileContent.b64);
        setUint8Array(convertedUint8);
      } catch (error) {
        console.error('Failed to convert base64 to Uint8Array:', error);
        setUint8Array(null);
      }
    } else {
      setUint8Array(null);
    }
  }, [selectedFileContent])

  const renderToolbar = (Toolbar) => (
    <Toolbar>
        {(slots) => {
            const {
                CurrentPageInput,
                GoToNextPage,
                GoToPreviousPage,
                NumberOfPages,
                Zoom,
                ZoomIn,
                ZoomOut,
                Rotate
            } = slots;
            return (
              <Grid container spacing={2} style={{ textAlign: 'center' }}>
                {/* PAGES SELECTION */}
                <Grid item xs={4} style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }} >
                  <GoToPreviousPage />
                  <CurrentPageInput /> /
                  <NumberOfPages />
                  <GoToNextPage />
                </Grid>
                {/* ZOOM */}
                <Grid item xs={4} style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }} >
                  <ZoomOut />
                  <Zoom />
                  <ZoomIn />
                </Grid>
                {/* ROTATE */}
                <Grid item xs={4} style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }} >
                  <Rotate />
                </Grid>
              </Grid>
            );
        }}
    </Toolbar>
);

const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: () => [],
});

  return (
    <div className='scrollbar-data-grid' style={{ height: '650px' }}>
      {/* IF IS ERROR SHOW ERROR */}
      {containsError(selectedFileContent) ? (
        <p>Errore visualizzazione pdf: {selectedFileContent.Error}</p>
      ) : (
        // IF PDF URL HAS FINISHED THE CONVERSION SHOW THE VIEWER
        uint8Array ? (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            {/* https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js */}
            <Viewer fileUrl={uint8Array} plugins={[defaultLayoutPluginInstance]} />
          </Worker>
        ) : (
          <p>Invalido</p>
        )
      )}
    </div>
  );
};

export default PdfViewer;