/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Container, Modal } from 'reactstrap';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../contexts/ContextProvider';
import { getDocumentContent } from '../../../api/api';
import { Spinner } from '../../../AbstractElements';
import PdfViewer from '../Documents/PDFViewer/PdFViewer';

function ViewEmergencyPlan() {
  const { basictoaster, primary } = useStateContext();
  const [open, setOpen] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const [emergencyPlanFileContent, setEmergencyPlanFileContent] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  const handleOpen = () => {
    // Create a new AbortController instance
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    // get data for the form selects
    setFileLoading(true);
    // get selections for all the form selects
    const emergencyPlanId = 2023;
    getDocumentContent(emergencyPlanId, newAbortController.signal).then((resp) => {
      if (!newAbortController.signal.aborted) {
        setEmergencyPlanFileContent(resp);
        setFileLoading(false);
      }
    }).catch((err) => {basictoaster("error", "Errore documenti", err, () => {})});
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    abortController.abort();
  };

  return (
    <>
      <Button variant='contained' style={{ backgroundColor: primary }} onClick={handleOpen}>Utleriori dettagli</Button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        size='xl'
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <Container fluid={true} style={{ padding: '30px', textAlign: 'center' }} >
          {fileLoading ? (
            <div className="loader-box">
              <Spinner attrSpinner={{ className: 'loader-1' }} />
            </div>
          ) : (
            <>
              <h4>Piano gestione emergenze:</h4>
              {emergencyPlanFileContent && (
                <PdfViewer selectedFileContent={emergencyPlanFileContent} />
              )}
            </>
          )}
        </Container>
      </Modal>
    </>
  );
}

export default ViewEmergencyPlan;
