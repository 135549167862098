/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState, useEffect } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { getUsers, changeUserPasswordFn } from '../../../api/api';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { FormGroup, Label, Container, Modal } from 'reactstrap';
import Button from '@mui/material/Button';
import { Eye, EyeOff } from 'react-feather';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}

function ChangeUserPass({ usrId }) {
  const { basictoaster, primary, setUsers, setLoading, setExecuting } = useStateContext();
  // init form data
  const [viewPass, setViewPass] = useState(false);
  const [formData, setFormData] = useState({
    new_password: '',
    new_password_confirm: '',
  });
  const [errors, setErrors] = useState({});
  // open
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    // open
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // validate from callback
  const validateForm = useCallback(() => {
    const { new_password, new_password_confirm } = formData;
    const newErrors = {};

    if (!new_password) {
      newErrors.new_password = 'la nuova password è richiesta';
    }
    if (!new_password_confirm) {
      newErrors.new_password_confirm = 'la conferma della nuova password è richiesta';
    }
    if (new_password !== new_password_confirm) {
      newErrors.new_passwords_not_matching = 'le password non corrispondono';
    }

    // validate password
    if (new_password && new_password_confirm && new_password === new_password_confirm) {
      const lowerCase = /[a-z]/;
      const upperCase = /[A-Z]/;
      const numbers = /[0-9]/;
      if (new_password.length < 8) {
        newErrors.passwordValidation = "La password dev'essere lunga almeno 8 caratteri";
      }
      if (!lowerCase.test(new_password)) {
        newErrors.passwordValidation = "La password deve contenere almeno una lettera minuscola.";
      }
      if (!upperCase.test(new_password)) {
        newErrors.passwordValidation = "La password deve contenere almeno una lettera maiuscola.";
      }
      if (!numbers.test(new_password)) {
        newErrors.passwordValidation = "La password deve contenere almeno un numero.";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  // validate form if input changes
  useEffect(() =>  {
    validateForm();
  }, [formData, validateForm])

  const changePassHandler = () => {
    SweetAlert.fire({
        title: 'Sei sicuro?',
        text: 'La password di questo utente verrà modificata',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            const changePassObj = {
              user_id: usrId,
              password: formData.new_password,
            };
            // console.log(connAgreement)
            await changeUserPasswordFn(changePassObj).then(async (response) => {
              getUsers().then((newsResp) => {
                setUsers(newsResp);
                setLoading(false);
              }).catch((err) => {basictoaster("error", "Errore utenti", err, () => {})});
              if (containsSuccess(response)) {
                  SweetAlert.fire(
                      'Fatto!',
                      'La password è modificata.',
                      'success'
                  );
                  // init form data
                  setFormData({
                    new_password: '',
                    new_password_confirm: '',
                  })
              } else if (containsError(response)) {
                SweetAlert.fire(
                    'Errore!',
                    `Errore: ${response.Error}.`,
                    'error'
                );
              }
              setExecuting(false);
              setOpen(false);
            }).catch((err) => {basictoaster("error", 'Errore modifica utenti', err, () => {})});
          }
        });
  };

  // submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      changePassHandler();
    }
  };

  return (
    <>
      <Button variant='contained' style={{ backgroundColor: primary }} onClick={handleOpen}>Cambia password</Button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <Container fluid={true} style={{ padding: '20px' }}>
          <h5>Cambia password:</h5><br />
          <form style={{ margin: '10%', marginTop: '1%', marginBottom: '1%' }} onSubmit={handleSubmit} >
            {/* NEW PASSWORD */}
            <FormGroup>
              <Label htmlFor="new_password" className="col-form-label pt-0">Nuova password</Label>
              <input
                className="form-control"
                type={viewPass ? 'text' : 'password'}
                id="new_password"
                name="new_password"
                value={formData.new_password}
                onChange={handleChange}
              />
              {/* {errors.new_password && <div style={{ color: 'red' }}>{errors.new_password}</div>} */}
            </FormGroup>
            {/* NEW PASSWORD CONFIRM*/}
            <FormGroup>
              <Label htmlFor="new_password_confirm" className="col-form-label pt-0">Conferma nuova password</Label>
              <input
                className="form-control"
                type={viewPass ? 'text' : 'password'}
                id="new_password_confirm"
                name="new_password_confirm"
                value={formData.new_password_confirm}
                onChange={handleChange}
              />
              {/* {errors.new_password_confirm && <div style={{ color: 'red' }}>{errors.new_password_confirm}</div>} */}
              {errors.new_passwords_not_matching && <div style={{ color: 'red' }}>{errors.new_passwords_not_matching}</div>}
              {errors.passwordValidation && <div style={{ color: 'red' }}>{errors.passwordValidation}</div>}
              <button type='button' className='imgButton' onClick={() => {setViewPass((prevState) => !prevState)}} >{viewPass ? <Eye width={20} /> : <EyeOff width={20} />}</button>
            </FormGroup>
            <br />
            {/* BUTTONS */}
            <Button
              variant="contained"
              type="submit"
              style={{ backgroundColor: primary }}
            >
              Conferma
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={handleClose}
              style={{ backgroundColor: 'gray', marginLeft: '1%' }}
            >
              Annulla
            </Button>
          </form>
        </Container>
      </Modal>
    </>
  );
}

export default ChangeUserPass;
