import React, { Fragment } from 'react';
import { Card } from 'reactstrap';
import { Btn, LI } from '../../../AbstractElements';
// import { firebase_app } from '../../../Config/Config';
import { LogIn, LogOut } from 'react-feather';
import { Link } from 'react-router-dom';

const LogoutClass = () => {
    const Logout = () => {
        localStorage.removeItem('logged');
        localStorage.removeItem('auth0_token');
        localStorage.removeItem('user_data');
        localStorage.removeItem('auth0_user_roles');
        window.location.reload();
    };

    // taking userDate to check if user is logged in
    const userData = JSON.parse(localStorage.getItem('user_data'));

    return (
        <Fragment>
            {userData !== null ? (
                <LI attrLI={{ className: 'onhover-dropdown p-0', onClick: Logout }} >
                    <Btn attrBtn={{ as: Card.Header, className: 'btn btn-primary-light', color: 'default' }} >
                        <Link to={`${process.env.PUBLIC_URL}/`}>
                            <LogOut />
                            Log out
                        </Link>
                    </Btn>
                </LI>
            ) : (
                <LI attrLI={{ className: 'onhover-dropdown p-0' }} >
                    <Link to={`${process.env.PUBLIC_URL}/login`}>
                        <Btn attrBtn={{ as: Card.Header, className: 'btn btn-primary-light', color: 'default' }} >
                            <LogIn />
                            Log in
                        </Btn>
                    </Link>
                </LI>
            )}
        </Fragment>
    );
};

export default LogoutClass;