import React, { Fragment } from 'react';
import { useState } from 'react';
import NavCustomizer from './NavCustomizer';
import TabCustomizer from './TabCustomizer';
// import { useStateContext } from '../../contexts/ContextProvider';

const Themecustomizer = () => {
  const [selected, setSelected] = useState('check-layout');
  const [openSettings, setOpenSettings] = useState(false);
  // const { basictoaster, setSettingsLoading, setSettings } = useStateContext();

  const openAndCloseSettingsH = ((select, open) => {
    setSelected(select);
    setOpenSettings(open);
  });

  // use effect to get settings every time the settings tab opens
  // useEffect(() => {
  //   // if open is true get the settings
  //   if (openSettings) {
  //     setSettingsLoading(true);
  //     getAllSettings().then((resp) => {
  //       setSettings(resp);
  //       setSettingsLoading(false);
  //     }).catch((err) => {
  //       setSettingsLoading(false);
  //       basictoaster("error", "Error getting settings", err, setSettings)
  //     })
  //   }
  // }, [basictoaster, openSettings, setSettings, setSettingsLoading])

  return (
    <Fragment>
      {/* <div className={`customizer-links ${openSettings ? 'open' : ''}`}>
        <NavCustomizer openAndCloseSettingsH={openAndCloseSettingsH} selected={selected} />
        <div className={`customizer-contain ${openSettings ? 'open' : ''}`}>
          <TabCustomizer selected={selected} openAndCloseSettingsH={openAndCloseSettingsH} />
        </div>
      </div> */}
    </Fragment>
  );
};

export default Themecustomizer;
