/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useStateContext } from '../../../contexts/ContextProvider';
import '../../../App.css'
import { Spinner } from '../../../AbstractElements';
import Grow from '@mui/material/Grow';
import { getGuestsMovementsFn, getFireFightersShifts, getNewsFn } from '../../../api/api';
import GuestsMovemetsTable from '../GuestsMovements/GuestsMovemetsTable';
import FireFightersShiftsTable from '../FireFightersShifts/FireFightersShiftsTable';
import NewsCards from '../News/NewsCards/NewsCards';
import NewsCarousel from '../News/NewsCarousel/NewsCarousel';
import Grid from '@mui/material/Grid';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

function getCurrentTimeString() {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
}

const Home = ({ kioskMode }) => {
  const { todayDate, getWeekdayName, convertDateYearAtEnd, basictoaster, loading, setLoading, guestsMovements, setGuestsMovements, fireFightersShifts, setFireFightersShifts, news, setNews } = useStateContext();
  // current datetime state for kiosk page
  const [currentTime, setCurrentTime] = useState(getCurrentTimeString());
  const [currentDate, setCurrentDate] = useState(todayDate());

  // update date
  useEffect(() => {
    const timerId = setInterval(() => {
      //set date 
      setCurrentDate(todayDate())
      // set time
      setCurrentTime(getCurrentTimeString());
    }, 10000); // Update every 10000 milliseconds (10 seconds)

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    // set ab to state and use this new ab in this code
    let newAbortController = new AbortController()
    setLoading(true);
    // get guest movements
    getGuestsMovementsFn('home', newAbortController.signal).then((guestMovementsResp) => {
      if (!newAbortController.signal.aborted) {
        setGuestsMovements(guestMovementsResp);
        // get firefighters shifts
        getFireFightersShifts('home', newAbortController.signal).then((fireFightersShiftsResp) => {
          if (!newAbortController.signal.aborted) {
            setFireFightersShifts(fireFightersShiftsResp);
            // get news limited if home 8 else if is kiosk 10
            getNewsFn(!kioskMode ? 8 : 10, newAbortController.signal).then((newsResp) => {
              if (!newAbortController.signal.aborted) {
                setNews(newsResp);
                setLoading(false);
              }
            }).catch((err) => {basictoaster("error", "Errore notizie", err, () => {})});
          }
        }).catch((err) => {basictoaster("error", "Errore turni antincendio", err, () => {})});
      }
    }).catch((err) => {basictoaster("error", "Errore movimenti ospiti", err, () => {})});
    
    // Cleanup function to abort the request when the component unmounts
    return () => {
      newAbortController.abort();
    };
  }, [])

  // ALERT
  useEffect(() => {
    if (isObject(guestsMovements) && containsError(guestsMovements)) {
      basictoaster("error", "Errore movimenti ospiti", guestsMovements.Error, setGuestsMovements)
    } else if (isObject(fireFightersShifts) && containsError(fireFightersShifts)) {
      basictoaster("error", "Errore turni antincendio", fireFightersShifts.Error, setFireFightersShifts)
    } else if (isObject(news) && containsError(news)) {
      basictoaster("error", "Errore notizie", news.Error, setNews)
    }
  }, [guestsMovements, fireFightersShifts, news])

  return (
    <Fragment>
    <br />
      {!kioskMode && <Breadcrumbs phish mainTitle="Home" parent="DomusVestra" title="Home" />}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Grow in={true}><div>
            <Card>
              <CardBody>
                {/* <NewReq /><br /><br /> */}
                {loading ? (
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-1' }} />
                  </div>
                ) : (
                  <>
                    {!kioskMode && <><h2><b>Home domus vestra</b></h2><br /></>}
                    {/* IF IS FROM KIOSK PUT ELEMENTS IN A GRID */}
                    {kioskMode ? (
                      <>
                        {/* KIOSK HOME */}
                        <h4 className='textCenter' >{getWeekdayName(currentDate)} {convertDateYearAtEnd(currentDate)} {currentTime}</h4>
                        <hr />
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            {/* GUESTS MOVEMENTS */}
                            <div className='kiosk-scale-smaller'>
                              <GuestsMovemetsTable fromHome kioskMode />
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            {/* FIREFIGHTERS SHIFTS */}
                            <div className='kiosk-scale-smaller'>
                              <FireFightersShiftsTable fromHome kioskMode />
                            </div>
                          </Grid>
                        </Grid>
                        <hr />
                        {/* NEWS CAROUSEL */}
                        <NewsCarousel />
                      </>
                    ) : (
                      <>
                        {/* NORMAL HOME */}
                        {/* GUESTS MOVEMENTS */}
                        <GuestsMovemetsTable fromHome />
                        <br /><br />
                        {/* FIREFIGHTERS SHIFTS */}
                        <FireFightersShiftsTable fromHome />
                        <br /><br />
                        {/* NEWS CARDS */}
                        <NewsCards fromHome />
                      </>
                    )}
                  </>
                )}
              </CardBody>
            </Card>
            </div></Grow>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Home;