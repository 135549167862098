/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../contexts/ContextProvider';
import { addGuestMovementFn, getGuestsMovementsFn, getRooms } from '../../../api/api';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { FormGroup, Label, Container, Modal, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../AbstractElements';
import SelectGuest from './SelectGuest/SelectGuest';
import ColoredSquare from '../ColoredSquare/ColoredSquare';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}

const colors = [
  '#FF0000', // Red
  '#00FF00', // Green
  '#006aff', // Blue
  '#FFFF00', // Yellow
  '#FF00FF', // Magenta
  '#00FFFF', // Cyan
  '#FFA500', // Orange
  '#a54aff', // Purple
  '#996932', // Brown
  '#1a8a50'  // Dark green
];

function AddGuestMovements() {
  const { t } = useTranslation();
  const { basictoaster, convertLocalToUtc, primary, setGuestsMovements, setLoading, setExecuting, rooms, setRooms } = useStateContext();
  const [open, setOpen] = useState(false);
  const [formLoading, setformLoading] = useState(false);
  const [formData, setFormData] = useState({
    id: 0,
    name: '',
    surname: '',
    birth_date: '',
    movement_type: 'noMovementType',
    expected_date: '',
    from_room: 'noRoom',
    to_room: 'noRoom',
    gender: '',
    colour: '',
    physician: '',
    // set the guest id when selecting the guest from guest selector
    guest_id: '',
    notes: '',
  });
  // const [errors, setErrors] = useState({});
  const [abortController, setAbortController] = useState(new AbortController());

  const handleOpen = () => {
    // Create a new AbortController instance
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    // get data for the form selects
    setformLoading(true);
    // get selections for all the form selects
    getRooms(newAbortController.signal).then((resp) => {
      if (!newAbortController.signal.aborted) {
        setRooms(resp);
        setformLoading(false);
      }
    }).catch((err) => {basictoaster("error", 'Errore stanze', err, () => {})})
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    abortController.abort();
  }
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value)
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleChangeMovementType = (e) => {
    // every time the movement type changes reset the whole form data so if the fields were compiled they resets
    const { value } = e.target;
    // console.log(value)
    setFormData({
      id: 0,
      name: '',
      surname: '',
      birth_date: '',
      movement_type: value,
      expected_date: '',
      from_room: 'noRoom',
      to_room: 'noRoom',
      gender: '',
      colour: '',
      physician: '',
      // set the guest id when selecting the guest from guest selector
      guest_id: '',
      notes: '',
    });
  };

  const validateForm = useCallback(() => {
    const { name, surname, birth_date, movement_type, expected_date, from_room, to_room, gender } = formData;
    const newErrors = {};

    if (!name) {
      newErrors.name = 'Il nome ospite è richiesto';
    }
    if (!surname) {
      newErrors.surname = 'Il cognome ospite è richiesto';
    }
    if (!birth_date) {
      newErrors.birth_date = 'La data di nascita è richiesta';
    }
    if (movement_type === 'noMovementType') {
      newErrors.movement_type = 'Il tipo di movimento è richiesto';
    }
    if (!expected_date) {
      newErrors.expected_date = 'La data prevista è richiesta';
    }
    // if movement type is 'entrata' to_room is requested
    if (movement_type === 'entrata' && to_room === 'noRoom') {
      newErrors.to_room = 'La stanza di destinazione è richiesta';
    }
    // if movement type is 'trasferimento' from_toom and to_room are requested
    if (movement_type === 'trasferimento' && from_room === 'noRoom' && to_room === 'noRoom') {
      newErrors.from_room = 'La stanza di provenienza è richiesta';
      newErrors.to_room = 'La stanza di destinazione è richiesta';
    }
    if (!gender) {
      newErrors.gender = 'Il genere è richiesto';
    }

    // setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  useEffect(() =>  {
    validateForm();
  }, [formData, validateForm])

  const addMovH = () => {
    SweetAlert.fire({
        title: 'Sei sicuro?',
        text: 'Questo movimento ospite verrà aggiunto',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            const movObj = {
              id: formData.id,
              name: formData.name,
              surname: formData.surname,
              birth_date: formData.birth_date,
              movement_type: formData.movement_type,
              expected_date: convertLocalToUtc(formData.expected_date), // converts the selected date from local timezone to UTC
              from_room: formData.from_room === 'noRoom' ? null : formData.from_room,
              to_room: formData.to_room === 'noRoom' ? null : formData.to_room,
              gender: formData.gender,
              colour: formData.colour === '' ? null : formData.colour,
              physician: formData.physician === '' ? null : formData.physician,
              guest_id: formData.guest_id === '' ? null : formData.guest_id,
              notes: formData.notes === '' ? null : formData.notes,
            };
            // console.log(movObj)
            await addGuestMovementFn(movObj).then(async (response) => {
              setLoading(true);
              getGuestsMovementsFn('always').then((resp) => {   
                setGuestsMovements(resp);
                setLoading(false);
              }).catch((err) => {basictoaster("error", "Errore movimenti ospiti", err, () => {})})
              if (containsSuccess(response)) {
                  SweetAlert.fire(
                      'Fatto!',
                      'Il tuo movimento ospite è stato aggiunto!',
                      'success'
                  );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    'Errore!',
                    `${t('Errore')}: ${response.Error}.`,
                    'error'
                );
              }
              setExecuting(false);
            }).catch((err) => {basictoaster("error", 'Errore nell\'aggiunta del movimento ospiti', err, () => {})});
          }
        });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      addMovH();
    }
  };

  // form fields for movtype 'entrata'
  const formFieldsEntrata = (
    <>
      {/* NAME */}
      <FormGroup>
        <Label htmlFor="name" className="col-form-label pt-0">Nome ospite <span className='form-required-symbol'>*</span></Label>
        <input
          className="form-control"
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        {/* {errors.name && <div style={{ color: 'red' }}>{errors.name}</div>} */}
      </FormGroup>
      {/* SURNAME */}
      <FormGroup>
        <Label htmlFor="surname" className="col-form-label pt-0">Cognome ospite <span className='form-required-symbol'>*</span></Label>
        <input
          className="form-control"
          type="text"
          id="surname"
          name="surname"
          value={formData.surname}
          onChange={handleChange}
        />
        {/* {errors.surname && <div style={{ color: 'red' }}>{errors.surname}</div>} */}
      </FormGroup>
      {/* BIRTH DATE */}
      <FormGroup>
        <Label htmlFor="birth_date" className="col-form-label pt-0">Data di nascita <span className='form-required-symbol'>*</span></Label>
        <input
          className="form-control"
          type="date"
          id="birth_date"
          name="birth_date"
          value={formData.birth_date}
          onChange={handleChange}
        />
        {/* {errors.birth_date && <div style={{ color: 'red' }}>{errors.birth_date}</div>} */}
      </FormGroup>
      {/* EXPECTED DATE */}
      <FormGroup>
        <Label htmlFor="expected_date" className="col-form-label pt-0">Data prevista <span className='form-required-symbol'>*</span></Label>
        <input
          className="form-control"
          type="datetime-local"
          id="expected_date"
          name="expected_date"
          value={formData.expected_date}
          onChange={handleChange}
        />
        {/* {errors.expected_date && <div style={{ color: 'red' }}>{errors.expected_date}</div>} */}
      </FormGroup>
      {/* NO FROM ROOM BECAUSE IS FROM ENTRATA */}
      {/* TO ROOM */}
      <FormGroup>
        <Label htmlFor="to_room" className="col-form-label pt-0">Stanza di destinazione <span className='form-required-symbol'>*</span></Label>
        <Input
          className="form-control"
          type="select"
          id="to_room"
          name="to_room"
          value={formData.to_room}
          onChange={handleChange}
        >
          <option value="noRoom">Nessuna stanza</option>
          {rooms && rooms.length > 0 && rooms.map((room) => (
              <option key={room.code} value={room.code}>
                {room.code}
              </option>
            )
          )}
        </Input>
        {/* {errors.to_room && <div style={{ color: 'red' }}>{errors.to_room}</div>} */}
      </FormGroup>
      {/* GENDER */}
      <FormGroup>
        <Label htmlFor="gender" className="col-form-label pt-0">Genere <span className='form-required-symbol'>*</span></Label>
        <input
          className="form-control"
          type="text"
          id="gender"
          name="gender"
          value={formData.gender}
          onChange={handleChange}
        />
        {/* {errors.gender && <div style={{ color: 'red' }}>{errors.gender}</div>} */}
      </FormGroup>
      {/* COLOUR */}
      <FormGroup>
        <Label htmlFor="colour" className="col-form-label pt-0">Colore</Label>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          {colors.map((color, index) => (
            <div
              key={index}
              onClick={() => handleChange({ target: { name: 'colour', value: color} })}
              style={{
                backgroundColor: color,
                width: '50px',
                height: '50px',
                margin: '5px',
                cursor: 'pointer',
                border: formData.colour === color ? '3px solid black' : '1px solid black'
              }}
            ></div>
          ))}
        </div>
        {/* {errors.colour && <div style={{ color: 'red' }}>{errors.colour}</div>} */}
      </FormGroup>
      {/* PHYSICIAN */}
      <FormGroup>
        <Label htmlFor="physician" className="col-form-label pt-0">Medico</Label>
        <input
          className="form-control"
          type="text"
          id="physician"
          name="physician"
          value={formData.physician}
          onChange={handleChange}
        />
        {/* {errors.physician && <div style={{ color: 'red' }}>{errors.physician}</div>} */}
      </FormGroup>
      {/* NOTES */}
      <FormGroup>
        <Label htmlFor="notes" className="col-form-label pt-0">Note</Label>
        <input
          className="form-control"
          type="text"
          id="notes"
          name="notes"
          value={formData.notes}
          onChange={handleChange}
        />
        {/* {errors.notes && <div style={{ color: 'red' }}>{errors.notes}</div>} */}
      </FormGroup>
    </>
  )
  
  // form fields for movtype 'trasferimento'
  const formFieldsTrasferimento = (
    <>
      {/* GUEST SELECT */}
      <FormGroup>
        <Label htmlFor="expected_date" className="col-form-label pt-0">Seleziona ospite <span className='form-required-symbol'>*</span></Label><br />
        <SelectGuest formData={formData} setFormData={setFormData} />
        <br />
        {/* when the guest is selected show the info */}
        {formData.name !== '' ? (
          <>
            <p><b>Ospite selezionato: </b></p>
            <p><b>Nome:</b> {formData.name}</p>
            <p><b>Cognome:</b> {formData.surname}</p>
            <p><b>Stanza di provenienza:</b> {formData.from_room}</p>
            <p><b>Data di nascita:</b> {formData.birth_date}</p>
            <p><b>Genere:</b> {formData.gender}</p>
            <p><b>Medico:</b> {formData.physician}</p>
          </>
        ) : (
          <>
            {/* <p style={{ color: 'red' }} >È necessatio selezionare l'ospite</p> */}
          </>
        )}
      </FormGroup>
      {/* EXPECTED DATE */}
      <FormGroup>
        <Label htmlFor="expected_date" className="col-form-label pt-0">Data prevista <span className='form-required-symbol'>*</span></Label>
        <input
          className="form-control"
          type="datetime-local"
          id="expected_date"
          name="expected_date"
          value={formData.expected_date}
          onChange={handleChange}
        />
        {/* {errors.expected_date && <div style={{ color: 'red' }}>{errors.expected_date}</div>} */}
      </FormGroup>
      {/* NO FROM ROOM BECAUSE IS FROM TRASFERIMENTO */}
      {/* TO ROOM */}
      <FormGroup>
        <Label htmlFor="to_room" className="col-form-label pt-0">Stanza di destinazione <span className='form-required-symbol'>*</span></Label>
        <Input
          className="form-control"
          type="select"
          id="to_room"
          name="to_room"
          value={formData.to_room}
          onChange={handleChange}
        >
          <option value="noRoom">Nessuna stanza</option>
          {rooms && rooms.length > 0 && rooms.map((room) => (
              <option key={room.code} value={room.code}>
                {room.code}
              </option>
            )
          )}
        </Input>
        {/* {errors.to_room && <div style={{ color: 'red' }}>{errors.to_room}</div>} */}
      </FormGroup>
      {/* COLOUR */}
      <FormGroup>
        <Label htmlFor="colour" className="col-form-label pt-0">Colore</Label>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          {colors.map((color, index) => (
            <div
              key={index}
              onClick={() => handleChange({ target: { name: 'colour', value: color} })}
              style={{
                backgroundColor: color,
                width: '50px',
                height: '50px',
                margin: '5px',
                cursor: 'pointer',
                border: formData.colour === color ? '3px solid black' : '1px solid black'
              }}
            ></div>
          ))}
        </div>
        {/* {errors.colour && <div style={{ color: 'red' }}>{errors.colour}</div>} */}
      </FormGroup>
      {/* NOTES */}
      <FormGroup>
        <Label htmlFor="notes" className="col-form-label pt-0">Note</Label>
        <input
          className="form-control"
          type="text"
          id="notes"
          name="notes"
          value={formData.notes}
          onChange={handleChange}
        />
        {/* {errors.notes && <div style={{ color: 'red' }}>{errors.notes}</div>} */}
      </FormGroup>
    </>
  )
  
  // form fields for movtype 'uscita'
  const formFieldsUscita = (
    <>
      {/* GUEST SELECT */}
      <FormGroup>
        <Label htmlFor="expected_date" className="col-form-label pt-0">Seleziona ospite <span className='form-required-symbol'>*</span></Label><br />
        <SelectGuest formData={formData} setFormData={setFormData} />
        <br />
        {/* when the guest is selected show the info */}
        {formData.name !== '' ? (
          <>
            <p><b>Ospite selezionato: </b></p>
            <p><b>Nome:</b> {formData.name}</p>
            <p><b>Cognome:</b> {formData.surname}</p>
            <p><b>Stanza di provenienza:</b> {formData.from_room}</p>
            <p><b>Data di nascita:</b> {formData.birth_date}</p>
            <p><b>Genere:</b> {formData.gender}</p>
            <div style={{ display: 'flex' }}><p style={{ marginRight: '1%' }} ><b>Colore:</b></p> {formData.colour ? <ColoredSquare bgColor={formData.colour} dimension='20px' /> : <p>Non specificatio</p>}</div>
            <p><b>Medico:</b> {formData.physician}</p>
          </>
        ) : (
          <>
            {/* <p style={{ color: 'red' }} >È necessatio selezionare l'ospite</p> */}
          </>
        )}
      </FormGroup>
      {/* EXPECTED DATE */}
      <FormGroup>
        <Label htmlFor="expected_date" className="col-form-label pt-0">Data prevista <span className='form-required-symbol'>*</span></Label>
        <input
          className="form-control"
          type="datetime-local"
          id="expected_date"
          name="expected_date"
          value={formData.expected_date}
          onChange={handleChange}
        />
        {/* {errors.expected_date && <div style={{ color: 'red' }}>{errors.expected_date}</div>} */}
      </FormGroup>
      {/* NO FROM ROOM BECAUSE IS FROM USCITA */}
      {/* NO TO ROOM BECAUSE IS FROM USCITA */}
      {/* NOTES */}
      <FormGroup>
        <Label htmlFor="notes" className="col-form-label pt-0">Note</Label>
        <input
          className="form-control"
          type="text"
          id="notes"
          name="notes"
          value={formData.notes}
          onChange={handleChange}
        />
        {/* {errors.notes && <div style={{ color: 'red' }}>{errors.notes}</div>} */}
      </FormGroup>
    </>
  )

  return (
    <>
      <Button variant="contained" style={{ backgroundColor: primary }} onClick={handleOpen}>Aggiungi movimento ospite</Button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <Container fluid={true} style={{ padding: '20px' }}>
          {formLoading ? (
            <div className="loader-box">
              <Spinner attrSpinner={{ className: 'loader-1' }} />
            </div>
          ) : (
            <form style={{ margin: '10%', marginTop: '1%', marginBottom: '1%' }} onSubmit={handleSubmit} >
              {/* MOVEMENT TYPE */}
              <FormGroup>
                <Label htmlFor="movement_type" className="col-form-label pt-0">Tipo movimento <span className='form-required-symbol'>*</span></Label>
                <Input
                  className="form-control"
                  type="select"
                  id="movement_type"
                  name="movement_type"
                  value={formData.movement_type}
                  onChange={handleChangeMovementType}
                >
                  <option value="noMovementType">Seleziona una tipologia di movimento ...</option>
                  <option value="entrata">Entrata</option>
                  <option value="trasferimento">Trasferimento</option>
                  <option value="uscita">Uscita</option>
                </Input>
                {/* {errors.movement_type && <div style={{ color: 'red' }}>{errors.movement_type}</div>} */}
              </FormGroup>
              {formData.movement_type === 'entrata' ? (
                <>
                  {/* ENTRATA FORM FIELDS */}
                  {formFieldsEntrata}
                </>
              ) : formData.movement_type === 'trasferimento' ? (
                <>
                  {/* TRASFERIMENTO FORM FIELDS */}
                  {formFieldsTrasferimento}
                </>
              ) : formData.movement_type === 'uscita' ? (
                <>
                  {/* USCITA FORM FIELDS */}
                  {formFieldsUscita}
                </>
              ) : (
                <></>
              )}
              <Button
                variant="contained"
                type="submit"
                style={{ backgroundColor: primary }}
                className=""
              >
                Aggiungi
              </Button>
              <Button
                variant="contained"
                type="button"
                onClick={handleClose}
                style={{ backgroundColor: 'gray', marginLeft: '1%' }}
                className=""
              >
                Annulla
              </Button>
            </form>
          )}
        </Container>
      </Modal>
    </>
  );
}

export default AddGuestMovements;
