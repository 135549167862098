/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Container, Modal } from 'reactstrap';
import Button from '@mui/material/Button';
import { Eye } from 'react-feather';
import ColoredSquare from '../ColoredSquare/ColoredSquare';
// import { useStateContext } from '../../../contexts/ContextProvider';

function ViewGuestsMovementsDetails({ guestMovement }) {
  // const { primary } = useStateContext();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }

  return (
    <>
      <button className='imgButton' onClick={handleOpen} ><Eye width={20} style={{ marginLeft: '%5' }} /></button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        size='lg'
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <Container fluid={true} style={{ padding: '30px', textAlign: 'center' }} >
          <h3><b>Dettagli movimento: </b></h3>
          <p><b>Data di nascita:</b> {guestMovement.birth_date ? guestMovement.birth_date : 'Non specificatio'}</p>
          <p><b>Genere:</b> {guestMovement.gender ? guestMovement.gender : 'Non specificatio'}</p>
          <div style={{ display: 'flex', justifyContent: 'center' }}><p style={{ marginRight: '1%' }} ><b>Colore:</b></p> {guestMovement.colour ? <ColoredSquare bgColor={guestMovement.colour} dimension='20px' /> : <p>Non specificatio</p>}</div>
          <p><b>Medico:</b> {guestMovement.physician ? guestMovement.physician : 'Non specificatio'}</p>
          <p><b>Note:</b> {guestMovement.notes ? guestMovement.notes : 'Non specificatio'}</p>
          <br />
          <Button variant='contained' style={{backgroundColor: 'gray'}} onClick={handleClose}>Chiudi</Button>
        </Container>
      </Modal>
    </>
  );
}

export default ViewGuestsMovementsDetails;
