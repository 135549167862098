import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import { H4 } from '../../../AbstractElements';
import { Password } from '../../../Constant';
// import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// import { firebase_app, Jwt_token } from '../../../Config/Config';
import { useStateContext } from '../../../contexts/ContextProvider';
// import man from '../../../assets/images/dashboard/1.png';
// import { handleResponse } from '../../../Services/fack.backend';
import { fetchLogin, validateToken } from '../../../api/api';
import { CircularProgress } from '@mui/material';
import { Button } from '@mui/material';
import { Grid } from '@mui/material';

// function containsError(item) {
//     return Object.prototype.hasOwnProperty.call(item, 'Error');
// }
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, ['https://app/roles']);
}
function containsError(item) {
    return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsError2(item) {
    return Object.prototype.hasOwnProperty.call(item, 'error');
}

const LoginTab = () => {
    const { basictoaster, setLoginResp, primary } = useStateContext();
    const [togglePassword, setTogglePassword] = useState(false);
    const [loadingLogIn, setLoadingLogIn] = useState(false);
    const navigate = useNavigate();

    const usernameRef = useRef('');
    const passwordRef = useRef('');

    const loginHandler = useCallback(async (event) => {
      event.preventDefault();
      setLoadingLogIn(true);
      const lObj = {
        username: usernameRef.current.value,
        password: passwordRef.current.value,
      };
      const login = await fetchLogin(lObj, setLoginResp);
      // if the login resp dosen't contain errors proceed with the validation of the retrieved token
      if (!containsError2(login)) {
        const token = localStorage.getItem('auth0_token');
        const validateResp = await validateToken(token);
        // if the login and the validate is successfull save info in local storage and redirect to /
        if (containsSuccess(validateResp)) {
          localStorage.setItem('user_data', JSON.stringify(validateResp['https://app/user_data']));
          localStorage.setItem('auth0_user_roles', JSON.stringify(validateResp['https://app/roles']));
          localStorage.setItem('logged', true);
          navigate('/');
        } else if (containsError(validateResp)) {
          // if is error norify and reset auth_token in local storage
          basictoaster('error', "Login fallito", validateResp.Error, () => {});
          localStorage.removeItem('auth0_token');
        }
      // else if login is failed:
      } else if (containsError2(login)) {
        // if the error is of type passwordreset redirect user to password reset page
        if (login.error_description === /*first reset error message*/"Please reset your password!" || login.error_description === /*pass expired error message*/"Your password has expired.  Please reset it.") {
          let errorMessage = "La tua password è scaduta";
          // if error is of type first login reset change the message
          if (login.error_description === "Please reset your password!") {
            errorMessage = "Al primo login è necessario cambiare la password"
          }
          // alert of pass expiration
          basictoaster('error', errorMessage, "Cambiala per favore.", () => {});
          // set in the local storage the insert username so that is already pre-compiled in the reset form
          localStorage.setItem('username', usernameRef.current.value);
          // redirect user to pass change page
          navigate('/reset-password');
        }
      }
      setLoadingLogIn(false);
    }, [basictoaster, navigate, setLoginResp])

    // function to login on the enter key press
    useEffect(() => {
      const handleKeyDown = (event) => {
          if (event.keyCode === 13) {
              loginHandler(event);
          }
      };

      const currentUsernameRef = usernameRef.current; // Capture the current ref value
      const currentPasswordRef = passwordRef.current; // Capture the current ref value

      // Add event listeners to input fields using the captured ref values
      currentUsernameRef.addEventListener('keydown', handleKeyDown);
      currentPasswordRef.addEventListener('keydown', handleKeyDown);

      // Clean up the event listeners using the captured ref values
      return () => {
          currentUsernameRef.removeEventListener('keydown', handleKeyDown);
          currentPasswordRef.removeEventListener('keydown', handleKeyDown);
      };
    }, [loginHandler]);

    return (
        <Fragment>
            <Form className="theme-form">
                <H4>Login</H4>
                <FormGroup>
                    <Label className="col-form-label">Nome utente</Label>
                    <input className="form-control" type="text" required="" ref={usernameRef} />
                </FormGroup>
                <FormGroup className="position-relative">
                    <Label className="col-form-label">{Password}</Label>
                    <input className="form-control" type={togglePassword ? 'text' : 'password'} ref={passwordRef} required="" />
                    <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? '' : 'show'}></span></div>
                </FormGroup>
                <div className="form-group mb-0">
                  <Grid container spacing={3}>
                    <Grid item xs={2}>
                      <Button variant='contained' style={{ backgroundColor: loadingLogIn ? 'gray' : primary }} onClick={(e) => loginHandler(e)} >{'Login'}</Button>
                    </Grid>
                    <Grid item xs={2}>
                      {loadingLogIn && <div style={{ marginTop: '12%', marginLeft: '30%' }} ><CircularProgress size={20} /></div>}
                    </Grid>
                  </Grid>
                </div>
            </Form>
        </Fragment>
    );
};

export default LoginTab;