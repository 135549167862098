import React, { useState } from 'react';
import { useStateContext } from '../../../../contexts/ContextProvider';
// import CardMedia from '@mui/material/CardMedia';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import NewsImagesCarousel from '../NewsCards/NewsImagesCarousel';

const NewsCarousel = () => {
  const { news, convertUtcToLocal, trimHTML } = useStateContext();
  const [activeIndex, setActiveIndex] = useState(0);

  const onChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      {news && news.length > 0 ? (
        <Carousel
          selectedItem={activeIndex}
          onChange={onChange}
          showThumbs={false}
          showStatus={false}
          infiniteLoop
          useKeyboardArrows
          autoPlay
          interval={35 * 1000}
        >
          {news.map((sNews) => (
            <div key={sNews.id} style={{ textAlign: 'center', width: '70%', margin: 'auto', marginBottom: '7%' }}>
              {/* CAROUSEL WITH ONLY IMAGE
              {sNews.attachments.length > 0 && (
                <div style={{ width: '20%', margin: 'auto' }}>
                  <NewsImagesCarousel attachments={sNews.attachments} />
                </div>
              )} */}
              {/* CONTENT */}
              <h3><b>{sNews.title}</b></h3>
              <p>{convertUtcToLocal(sNews.publish_date)}</p>
              <hr />
              {/* NEWS BODY */}
              <div dangerouslySetInnerHTML={{ __html: trimHTML(sNews.body, 450) }} />
            </div>
          ))}
        </Carousel>
      ) : (
        <p>Nessuna notizia trovata ...</p>
      )}
    </>
  );
};

export default NewsCarousel