/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../../contexts/ContextProvider';
import GuestSearchBar from './GuestSearchBar';
import { Modal, Container } from 'reactstrap';
import { Spinner } from '../../../../AbstractElements';
import Grow from '@mui/material/Grow';
import { getGuestsFn } from '../../../../api/api';
import SelectGuestsTable from './SelectGuestsTable';

function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}
function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}

function SelectGuest({ formData, setFormData }) {
  const [open, setOpen] = useState(false);
  const [guestsLoading, setGuestsLoading] = useState(false);
  const { basictoaster, primary, guests, setGuests } = useStateContext();
  let abortController = new AbortController();

  const handleOpen = () => {
    setGuestsLoading(true);
    getGuestsFn(abortController.signal).then((newsResp) => {
      if (!abortController.signal.aborted) {
        setGuests(newsResp);
        setGuestsLoading(false);
      }
    }).catch((err) => {basictoaster("error", "Errore ospiti", err, () => {})});
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  }

  function selectGuestH(selObj) {
    setFormData({
      ...formData,
      name: selObj.name,
      surname: selObj.surname,
      from_room: selObj.room,
      birth_date: selObj.birth_date,
      gender: selObj.gender,
      colour: selObj.colour,
      physician: selObj.physician,
      guest_id: selObj.id
    });
    setOpen(false);
  }

  const guestIsObj = isObject(guests);
  const guestIsErr = containsError(guests);

  return (
    <>
      <Button
        variant="contained"
        type="button"
        onClick={handleOpen}
        style={{ backgroundColor: primary }}
        className=""
      >
        Seleziona l'ospite
      </Button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
        size='lg'
      >
        <Container fluid={true} style={{ padding: '30px' }}>
          <h3>Seleziona l'ospite</h3>
          <br />
          <GuestSearchBar />
          {guestsLoading ? (
            <div className="loader-box">
              <Spinner attrSpinner={{ className: 'loader-1' }} />
            </div>
          ) : (
            <>
              <br />
              <Grow in={!guestsLoading}><div>
                {!guestIsObj && guests.length > 0
                  ? (
                    <SelectGuestsTable selectGuestH={selectGuestH} />
                  )
                  : (
                    <h5>Nessun ospite trovato ...</h5>
                  )}
                {guestIsErr && (
                  <div className="generalDiv dark:text-gray">
                    <h2>Errore ospiti: {guests.Error}</h2>
                  </div>
                )}
              </div></Grow>
            </>
          )}
        </Container>
      </Modal>
    </>
  );
}

export default SelectGuest;
