/* eslint-disable react-hooks/exhaustive-deps */
import React, { } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import '../../../App.css'
import { DataGrid } from '@mui/x-data-grid';
import ViewGuestDetails from './ViewGuestDetails';
import UpdateGuest from './UpdateGuest';
import ColoredSquare from '../ColoredSquare/ColoredSquare';

const GuestsTable = () => {
  const { guests } = useStateContext();
  
  const columns = [
    {
      field: 'name',
      headerName: 'Nome',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'surname',
      headerName: 'Cognome',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'room',
      headerName: 'Stanza',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'colour',
      headerName: 'Colore',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          {params.value ? <ColoredSquare bgColor={params.value} dimension='30px' /> : <p>Non specificato</p>}
        </div>
      ),
    },
    {
      field: 'details',
      headerName: '',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          <ViewGuestDetails guest={params.row} />
        </div>
      ),
    },
    {
      field: 'update',
      headerName: '',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          <UpdateGuest oldGuest={params.row} />
        </div>
      ),
    },
  ];

  return (
    <>
      {guests.length > 0 ? (
        <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
          <div style={{ width: '100%' }} className='scrollbar-data-grid'>
            <DataGrid
              rows={guests}
              columns={columns}
              getRowId={(row) => row.id}
              initialState={{
                pagination: {
                  // if is from home set the defualt pagesize to 5 so it's shorter otherwise set it to 10
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10, 15]}
              rowHeight={80}
              rowSelection={false}
            />
          </div>
        </div>
      ) : (
        <p>Nessun ospite trovato...</p>
      )}
    </>
  );
};

export default GuestsTable;