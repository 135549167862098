/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../contexts/ContextProvider';
import { updateGuestMovementFn, getGuestsMovementsFn, getRooms } from '../../../api/api';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { FormGroup, Label, Container, Modal, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../AbstractElements';
import { Edit } from 'react-feather';
import ColoredSquare from '../ColoredSquare/ColoredSquare';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}

function UpdateGuestMovements({ oldGuestMovement }) {
  const { t } = useTranslation();
  const { basictoaster, convertUtcToLocal, convertLocalToUtc, primary, setGuestsMovements, setLoading, setExecuting, rooms, setRooms } = useStateContext();
  const [open, setOpen] = useState(false);
  const [formLoading, setformLoading] = useState(false);
  // setting old expected date state
  const [formData, setFormData] = useState({
    id: 0,
    name: '',
    surname: '',
    birth_date: '',
    movement_type: 'noMovementType',
    expected_date: '',
    from_room: 'noRoom',
    to_room: 'noRoom',
    gender: '',
    colour: '',
    physician: '',
    guest_id: '',
    notes: '',
  });
  const [errors, setErrors] = useState({});
  const [abortController, setAbortController] = useState(new AbortController());

  // handle open
  const handleOpen = () => {
    // Create a new AbortController instance
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    // get data for the form selects
    setformLoading(true);
    // get selections for all the form selects
    getRooms(newAbortController.signal).then((resp) => {
      if (!newAbortController.signal.aborted) {
        setRooms(resp);
        setformLoading(false);
      }
    }).catch((err) => {basictoaster("error", 'Errore stanze', err, () => {})})
    // pre compile the form data from the selected old user
    setFormData({
      id: oldGuestMovement.id,
      name: oldGuestMovement.name,
      surname: oldGuestMovement.surname,
      birth_date: oldGuestMovement.birth_date,
      movement_type: oldGuestMovement.movement_type,
      expected_date: convertUtcToLocal(oldGuestMovement.expected_date), // in db the expected date is saved in utc so for compiling in the form re convert to local
      from_room: oldGuestMovement.from_room === null ? 'noRoom' : oldGuestMovement.from_room,
      to_room: oldGuestMovement.to_room === null ? 'noRoom' : oldGuestMovement.to_room,
      gender: oldGuestMovement.gender,
      colour: oldGuestMovement.colour === null ? '' : oldGuestMovement.colour,
      physician: oldGuestMovement.physician === null ? '' : oldGuestMovement.physician,
      guest_id: oldGuestMovement.guest_id === null ? '' : oldGuestMovement.physician,
      notes: oldGuestMovement.notes === null ? '' : oldGuestMovement.notes,
    })
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    abortController.abort();
  }
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleChangeMovementType = (e) => {
    // when changing the movement type reset also the from room and to room to prevent errors
    // error example: if i choose entrata for movement type the from room disappers but if i had already changed it it gets resetted to noRoom
    const { name, value } = e.target;
    // console.log(value)
    setFormData({
      ...formData,
      [name]: value,
      to_room: 'noRoom',
      from_room: 'noRoom'
    });
  };

  const validateForm = useCallback(() => {
    const { name, surname, movement_type, gender } = formData;
    const newErrors = {};

    if (!name) {
      newErrors.name = 'Il nome ospite è richiesto';
    }
    if (!surname) {
      newErrors.surname = 'Il cognome ospite è richiesto';
    }
    if (movement_type === 'noMovementType') {
      newErrors.movement_type = 'Il tipo di movimento è richiesto';
    }
    if (!gender) {
      newErrors.gender = 'Il genere è richiesto';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  useEffect(() =>  {
    validateForm();
  }, [formData, validateForm])

  const updateMovH = () => {
    SweetAlert.fire({
        title: 'Sei sicuro?',
        text: 'Questo movimento ospite verrà modificato',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            const movObj = {
              id: formData.id,
              name: formData.name,
              surname: formData.surname,
              birth_date: formData.birth_date,
              movement_type: formData.movement_type,
              expected_date: convertLocalToUtc(formData.expected_date), // converts the selected date from local timezone to UTC
              from_room: formData.from_room === 'noRoom' ? null : formData.from_room,
              to_room: formData.to_room === 'noRoom' ? null : formData.to_room,
              gender: formData.gender,
              colour: formData.colour === '' ? null : formData.colour,
              physician: formData.physician === '' ? null : formData.physician,
              guest_id: formData.guest_id === '' ? null : formData.guest_id,
              notes: formData.notes === '' ? null : formData.notes,
            };
            // console.log(movObj)
            await updateGuestMovementFn(movObj).then(async (response) => {
              setLoading(true);
              getGuestsMovementsFn('always').then((resp) => {   
                setGuestsMovements(resp);
                setLoading(false);
              }).catch((err) => {basictoaster("error", "Errore movimenti ospiti", err, () => {})})
              if (containsSuccess(response)) {
                  SweetAlert.fire(
                      'Fatto!',
                      'Il tuo movimento ospite è stato modificato!',
                      'success'
                  );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    'Errore!',
                    `${t('Errore')}: ${response.Error}.`,
                    'error'
                );
              }
              setExecuting(false);
            }).catch((err) => {basictoaster("error", 'Errore nella modifica del movimento ospiti', err, () => {})});
          }
        });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      updateMovH();
    }
  };

  return (
    <>
      <button className='imgButton' onClick={handleOpen}><Edit width={20} /></button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <Container fluid={true} style={{ padding: '20px' }}>
          {formLoading ? (
            <div className="loader-box">
              <Spinner attrSpinner={{ className: 'loader-1' }} />
            </div>
          ) : (
            <form style={{ margin: '10%', marginTop: '1%', marginBottom: '1%' }} onSubmit={handleSubmit} >
              {/* MOVEMENT TYPE */}
              <FormGroup>
                <Label htmlFor="movement_type" className="col-form-label pt-0">Tipo movimento</Label>
                <Input
                  className="form-control"
                  type="select"
                  id="movement_type"
                  name="movement_type"
                  value={formData.movement_type}
                  onChange={handleChangeMovementType}
                  disabled
                >
                  <option value="noMovementType">Seleziona una tipologia di movimento ...</option>
                  <option value="entrata">Entrata</option>
                  <option value="trasferimento">Trasferimento</option>
                  <option value="uscita">Uscita</option>
                </Input>
                {errors.movement_type && <div style={{ color: 'red' }}>{errors.movement_type}</div>}
              </FormGroup>
              {/* GUEST NAME */}
              <FormGroup>
                <Label htmlFor="name" className="col-form-label pt-0">Nome ospite</Label>
                <input
                  className="form-control"
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  disabled
                />
                {errors.name && <div style={{ color: 'red' }}>{errors.name}</div>}
              </FormGroup>
              {/* GUEST SURNAME */}
              <FormGroup>
                <Label htmlFor="surname" className="col-form-label pt-0">Cognome ospite</Label>
                <input
                  className="form-control"
                  type="text"
                  id="surname"
                  name="surname"
                  value={formData.surname}
                  onChange={handleChange}
                  disabled
                />
                {errors.surname && <div style={{ color: 'red' }}>{errors.surname}</div>}
              </FormGroup>
              {/* BIRTH DATE */}
              <FormGroup>
                <Label htmlFor="birth_date" className="col-form-label pt-0">Data di nascita</Label>
                <input
                  className="form-control"
                  type="date"
                  id="birth_date"
                  name="birth_date"
                  value={formData.birth_date}
                  onChange={handleChange}
                  disabled
                />
                {/* {errors.birth_date && <div style={{ color: 'red' }}>{errors.birth_date}</div>} */}
              </FormGroup>
              {/* EXPECTED DATE */}
              <FormGroup>
                <Label htmlFor="expected_date" className="col-form-label pt-0">Data prevista</Label>
                <input
                  className="form-control"
                  type="datetime-local"
                  id="expected_date"
                  name="expected_date"
                  value={formData.expected_date}
                  onChange={handleChange}
                />
                {errors.expected_date && <div style={{ color: 'red' }}>{errors.expected_date}</div>}
              </FormGroup>
              {/* FROM ROOM if movement type is different than 'noMovementType' and 'entrata' show this*/}
              {formData.movement_type !== 'noMovementType' && formData.movement_type !== 'entrata' && (
                <FormGroup> 
                  <Label htmlFor="from_room" className="col-form-label pt-0">Stanza di provenienza</Label>
                  <Input
                    className="form-control"
                    type="select"
                    id="from_room"
                    name="from_room"
                    value={formData.from_room}
                    onChange={handleChange}
                    disabled
                  >
                    <option value="noRoom">Nessuna stanza</option>
                    {rooms && rooms.length > 0 && rooms.map((room) => (
                        <option key={room.code} value={room.code}>
                          {room.code}
                        </option>
                      )
                    )}
                  </Input>
                  {errors.from_room && <div style={{ color: 'red' }}>{errors.from_room}</div>}
                </FormGroup>
              )}
              {/* TO ROOM if movement type is different than 'noMovementType' and 'uscita' show this*/}
              {formData.movement_type !== 'noMovementType' && formData.movement_type !== 'uscita' && (
                <FormGroup>
                  <Label htmlFor="to_room" className="col-form-label pt-0">Stanza di destinazione</Label>
                  <Input
                    className="form-control"
                    type="select"
                    id="to_room"
                    name="to_room"
                    value={formData.to_room}
                    onChange={handleChange}
                  >
                    <option value="noRoom">Nessuna stanza</option>
                    {rooms && rooms.length > 0 && rooms.map((room) => (
                        <option key={room.code} value={room.code}>
                          {room.code}
                        </option>
                      )
                    )}
                  </Input>
                  {errors.to_room && <div style={{ color: 'red' }}>{errors.to_room}</div>}
                </FormGroup>
              )}
              {/* GENDER */}
              <FormGroup>
                <Label htmlFor="gender" className="col-form-label pt-0">Genere</Label>
                <input
                  className="form-control"
                  type="text"
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  disabled
                />
                {errors.gender && <div style={{ color: 'red' }}>{errors.gender}</div>}
              </FormGroup>
              {/* COLOUR */}
              <FormGroup>
                <Label htmlFor="colour" className="col-form-label pt-0">Colore</Label>
                <br />
                <ColoredSquare bgColor={formData.colour} dimension='30px' />
                {errors.colour && <div style={{ color: 'red' }}>{errors.colour}</div>}
              </FormGroup>
              {/* PHYSICIAN */}
              <FormGroup>
                <Label htmlFor="physician" className="col-form-label pt-0">Medico</Label>
                <input
                  className="form-control"
                  type="text"
                  id="physician"
                  name="physician"
                  value={formData.physician}
                  onChange={handleChange}
                  disabled
                />
                {errors.physician && <div style={{ color: 'red' }}>{errors.physician}</div>}
              </FormGroup>
              {/* NOTES */}
              <FormGroup>
                <Label htmlFor="notes" className="col-form-label pt-0">Note</Label>
                <input
                  className="form-control"
                  type="text"
                  id="notes"
                  name="notes"
                  value={formData.notes}
                  onChange={handleChange}
                />
                {errors.notes && <div style={{ color: 'red' }}>{errors.notes}</div>}
              </FormGroup>
              <Button
                variant="contained"
                type="submit"
                style={{ backgroundColor: primary }}
                className=""
              >
                Modifica
              </Button>
              <Button
                variant="contained"
                type="button"
                onClick={handleClose}
                style={{ backgroundColor: 'gray', marginLeft: '1%' }}
                className=""
              >
                Annulla
              </Button>
            </form>
          )}
        </Container>
      </Modal>
    </>
  );
}

export default UpdateGuestMovements;
