import React, { useEffect, useRef } from 'react';
import Quill from 'quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({ onChange, height }) => {
  const quillRef = useRef(null);

  useEffect(() => {
    if (quillRef.current) {
      const quill = new Quill(quillRef.current, {
        theme: 'snow', // or 'bubble'
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', { 'color': [] }],
          ]
        }
      });

      quill.on('text-change', () => {
        onChange(quill.root.innerHTML)
      });
    }
  }, [onChange]);

  return (
    <div className='custom-editor-container'>
      <div style={{ height: height }} ref={quillRef}></div>
    </div>
  );
};

export default TextEditor;