import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { FormGroup, Label } from 'reactstrap';
import { H4 } from '../../../AbstractElements';
import { Grid } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { Button } from '@mui/material';
import { useStateContext } from '../../../contexts/ContextProvider';
import { resetUserPassFn } from '../../../api/api';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { Eye, EyeOff } from 'react-feather';

function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}
function containsError(item) {
    return Object.prototype.hasOwnProperty.call(item, 'Error');
}

const ResetPassword = () => {
  const { basictoaster, primary } = useStateContext();
  const [viewPass, setViewPass] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    username: '',
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // get username from local storage if it's present
  useEffect(() => {
    let localStorageUsername = localStorage.getItem('username');
    if (localStorageUsername) {
      setFormData(oldFormData => ({
        ...oldFormData,
        username: localStorageUsername,
      }));
    }
  }, [])

  // validate from callback
  const validateForm = useCallback(() => {
    const { username, oldPassword, newPassword, newPasswordConfirm } = formData;
    const newErrors = {};

    if (!username) {
      newErrors.username = 'Il nome utente è richiesto';
    }
    if (!oldPassword) {
      newErrors.oldPassword = 'La vecchia password è richiesta';
    }
    if (!newPassword) {
      newErrors.newPassword = 'La nuova password è richiesta';
    }
    if (!newPasswordConfirm) {
      newErrors.newPasswordConfirm = 'La conferma nuova password è richiesta';
    }
    if (newPassword !== newPasswordConfirm) {
      newErrors.passwordNotMatching = 'Le due nuove password non corrispondono';
    }

    // validate password
    if (newPassword && newPasswordConfirm && newPassword === newPasswordConfirm) {
      const lowerCase = /[a-z]/;
      const upperCase = /[A-Z]/;
      const numbers = /[0-9]/;
      if (newPassword.length < 8) {
        newErrors.passwordValidation = "La password dev'essere lunga almeno 8 caratteri";
      }
      if (!lowerCase.test(newPassword)) {
        newErrors.passwordValidation = "La password deve contenere almeno una lettera minuscola.";
      }
      if (!upperCase.test(newPassword)) {
        newErrors.passwordValidation = "La password deve contenere almeno una lettera maiuscola.";
      }
      if (!numbers.test(newPassword)) {
        newErrors.passwordValidation = "La password deve contenere almeno un numero.";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  // validate form if input changes
  useEffect(() =>  {
    validateForm();
  }, [formData, validateForm])

  const resetHandler = () => {
    SweetAlert.fire({
        title: 'Sei sicuro?',
        text: 'La tua password verrà cambiata',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setLoadingReset(true);
            const resetObj = {
              username: formData.username,
              old_password: formData.oldPassword,
              new_password: formData.newPassword,
            };
            resetUserPassFn(resetObj).then((response) => {
              // console.log(response);
              if (containsSuccess(response)) {
                  SweetAlert.fire(
                      'Fatto!',
                      'La tua password è stata cambiata.',
                      'success'
                  ); 
                  setLoadingReset(false);
                  // logout user and send to login page
                  localStorage.removeItem('username');
                  localStorage.removeItem('logged');
                  localStorage.removeItem('auth0_token');
                  localStorage.removeItem('user_data');
                  localStorage.removeItem('auth0_user_roles');
                  navigate('/login');
              } else if (containsError(response)) {
                SweetAlert.fire(
                    'Errore!',
                    `Errore: ${response.Error}.`,
                    'error'
                );
                setLoadingReset(false);
              }
            }).catch((err) => {basictoaster("error", 'Errore aggiunta utenti', err, () => {})});
          }
        });
  }

  // submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      resetHandler();
    }
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div className="login-main login-tab roundLogin">
              <Fragment>
                  <form className="theme-form" onSubmit={handleSubmit}>
                      <H4>Cambia password</H4>
                      <p>La tua password è scaduta, cambiala</p>
                      {/* USERNAME */}
                      <FormGroup>
                        <Label htmlFor="username" className="col-form-label pt-0">Nome utente</Label>
                        <input
                          className="form-control"
                          type="text"
                          id="username"
                          name="username"
                          value={formData.username}
                          onChange={handleChange}
                        />
                        {/* {errors.username && <div style={{ color: 'red' }}>{errors.username}</div>} */}
                      </FormGroup>
                      {/* OLD PASSWORD */}
                      <FormGroup>
                        <Label htmlFor="oldPassword" className="col-form-label pt-0">Vecchia password</Label>
                        <input
                          className="form-control"
                          type={viewPass ? 'text' : 'password'}
                          id="oldPassword"
                          name="oldPassword"
                          value={formData.oldPassword}
                          onChange={handleChange}
                        />
                        {/* {errors.oldPassword && <div style={{ color: 'red' }}>{errors.oldPassword}</div>} */}
                      </FormGroup>
                      {/* NEW PASSWORD */}
                      <FormGroup>
                        <Label htmlFor="newPassword" className="col-form-label pt-0">Nuova password</Label>
                        <input
                          className="form-control"
                          type={viewPass ? 'text' : 'password'}
                          id="newPassword"
                          name="newPassword"
                          value={formData.newPassword}
                          onChange={handleChange}
                        />
                        {/* {errors.newPassword && <div style={{ color: 'red' }}>{errors.newPassword}</div>} */}
                      </FormGroup>
                      {/* NEW PASSWORD CONFIRM*/}
                      <FormGroup>
                        <Label htmlFor="newPasswordConfirm" className="col-form-label pt-0">Conferma nuova password</Label>
                        <input
                          className="form-control"
                          type={viewPass ? 'text' : 'password'}
                          id="newPasswordConfirm"
                          name="newPasswordConfirm"
                          value={formData.newPasswordConfirm}
                          onChange={handleChange}
                        />
                        {/* {errors.newPasswordConfirm && <div style={{ color: 'red' }}>{errors.newPasswordConfirm}</div>} */}
                        {errors.passwordNotMatching && <div style={{ color: 'red' }}>{errors.passwordNotMatching}</div>}
                        {errors.passwordValidation && <div style={{ color: 'red' }}>{errors.passwordValidation}</div>}
                        <button type='button' className='imgButton' onClick={() => {setViewPass((prevState) => !prevState)}} >{viewPass ? <Eye width={20} /> : <EyeOff width={20} />}</button>
                      </FormGroup>
                      <div className="form-group mb-0">
                        <Grid container spacing={3}>
                          <Grid item xs={2}>
                            <Button variant='contained' style={{ backgroundColor: loadingReset ? 'gray' : primary }} type='submit'>Cambia</Button>
                          </Grid>
                          <Grid item xs={2}>
                            {loadingReset && <div style={{ marginTop: '12%', marginLeft: '30%' }} ><CircularProgress size={20} /></div>}
                          </Grid>
                        </Grid>
                      </div>
                  </form>
              </Fragment>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;