import React, { useState, useEffect } from 'react';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { Carousel } from 'react-responsive-carousel';

const isAnImage = (attachment) => {
  let attachmentName = attachment.name;
  // if ends with png,jpg,jpeg,webp is an image
  if (attachmentName.endsWith('png') || attachmentName.endsWith('jpg') || attachmentName.endsWith('jpeg') || attachmentName.endsWith('webp')) {
    return true
  }
  return false
}

const filterAllImagesAttachments = (attachmentsArr) => {
  // remove all the attachments that aren't images
  return attachmentsArr.filter(isAnImage)
}

const NewsImagesCarousel = ({ attachments }) => {
  const { addB64HeadersIfMissing } = useStateContext();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setActiveIndex(0);
      setIsMounted(false);
    };
  }, []);

  const onChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <Carousel
      selectedItem={activeIndex}
      onChange={onChange}
      showThumbs={false}
      showStatus={false}
      infiniteLoop
      useKeyboardArrows
      autoPlay={isMounted ? true : false}
      interval={5 * 1000}
    >
      {filterAllImagesAttachments(attachments).map((imageAttachment, index) => (
        <div key={index} style={{ textAlign: 'center', width: '70%', margin: 'auto', marginBottom: '7%' }}>
          <br />
          <img src={addB64HeadersIfMissing(imageAttachment.content)} alt='imageAttachment' />
        </div>
      ))}
    </Carousel>
  )
}

export default NewsImagesCarousel