/* eslint-disable react-hooks/exhaustive-deps */
import React, { } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import '../../../App.css'
import { DataGrid } from '@mui/x-data-grid';
import UpdateGuestMovement from './UpdateGuestMovements';
import ViewGuestsMovementsDetails from './ViewGuestsMovementsDetails';
import DeleteGuestMovements from './DeleteGuestMovements';
// import ColoredSquare from '../ColoredSquare/ColoredSquare';

const GuestsMovemetsTable = ({ fromHome, kioskMode }) => {
  const { convertUtcToLocal, guestsMovements } = useStateContext();
  
  const getRowBackgroundColorClassName = (params) => {
    // if movement is 'entrata' then set the class name to 'entrata'
    return `${params.row.movement_type}`;
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Nome ospite',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'surname',
      headerName: 'Cognome ospite',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'movement_type',
      headerName: 'Tipo movimento',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}> 
          {params.value}
        </div>
      ),
    },
    {
      field: 'expected_date',
      headerName: 'Data prevista',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          {params.value && convertUtcToLocal(params.value)}
        </div>
      ),
    },
    {
      field: 'from_room',
      headerName: 'Stanza di provenienza',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'to_room',
      headerName: 'Stanza di destinazione',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          {params.value}
        </div>
      ),
    },
    // {
    //   field: 'gender',
    //   headerName: 'Genere',
    //   headerClassName: 'datagrid-table-custom-headers',
    //   headerAlign: 'center',
    //   align: 'center',
    //   flex: 1,
    //   minWidth: 110,
    //   renderCell: (params) => (
    //     <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
    //       {params.value}
    //     </div>
    //   ),
    // },
    // {
    //   field: 'colour',
    //   headerName: 'Colore',
    //   headerClassName: 'datagrid-table-custom-headers',
    //   headerAlign: 'center',
    //   align: 'center',
    //   flex: 1,
    //   minWidth: 110,
    //   renderCell: (params) => (
    //     <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
    //       <ColoredSquare bgColor={params.value} dimension='30px' />
    //     </div>
    //   ),
    // },
    // {
    //   field: 'physician',
    //   headerName: 'Medico',
    //   headerClassName: 'datagrid-table-custom-headers',
    //   headerAlign: 'center',
    //   align: 'center',
    //   flex: 1,
    //   minWidth: 110,
    //   renderCell: (params) => (
    //     <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
    //       {params.value}
    //     </div>
    //   ),
    // },
    // {
    //   field: 'notes',
    //   headerName: 'Note',
    //   headerClassName: 'datagrid-table-custom-headers',
    //   headerAlign: 'center',
    //   align: 'center',
    //   flex: 1,
    //   minWidth: 110,
    //   renderCell: (params) => (
    //     <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
    //       {params.value}
    //     </div>
    //   ),
    // },
  ];

  // if is not from kiosk add also the view details button
  if (!kioskMode) {
    columns.push(
      {
        field: 'details',
        headerName: '',
        headerClassName: 'datagrid-table-custom-headers',
        headerAlign: 'center',
        align: 'center',
        width: 100,
        renderCell: (params) => (
          <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
            <ViewGuestsMovementsDetails guestMovement={params.row} />
          </div>
        ),
      },
    )
  }

  // if is not from home add also the update button column
  if (!fromHome) {
    columns.push(
      {
        field: 'update',
        headerName: '',
        headerClassName: 'datagrid-table-custom-headers',
        headerAlign: 'center',
        align: 'center',
        width: 100,
        renderCell: (params) => (
          <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
            <UpdateGuestMovement oldGuestMovement={params.row} />
          </div>
        ),
      },
    )
  }

  // after inserting the optional fields push the delete field if not from kiosk
  if (!kioskMode) {
    columns.push(
      {
        field: 'id',
        headerName: '',
        headerClassName: 'datagrid-table-custom-headers',
        headerAlign: 'center',
        align: 'center',
        width: 100,
        renderCell: (params) => (
          <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
            <DeleteGuestMovements movId={params.value} />
          </div>
        ),
      },
    )
  }

  return (
    <>
      {fromHome && <h4>Movimento ospiti:</h4>}
      {guestsMovements.length > 0 ? (
        <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
          <div style={{ width: '100%' }} className='scrollbar-data-grid'>
            <DataGrid
              rows={guestsMovements}
              columns={columns}
              getRowId={(row) => row.id}
              getRowClassName={getRowBackgroundColorClassName}
              initialState={{
                pagination: {
                  // if is from home set the defualt pagesize to 5 so it's shorter otherwise set it to 10
                  paginationModel: { page: 0, pageSize: fromHome ? 5 : 10 },
                },
              }}
              pageSizeOptions={[5, 10, 15]}
              rowHeight={80}
              rowSelection={false}
            />
          </div>
        </div>
      ) : (
        <p>Nessun movimento ospiti trovato {fromHome && 'per oggi '}...</p>
      )}
    </>
  );
};

export default GuestsMovemetsTable;