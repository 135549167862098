/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Unstable_Grid2';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { getGuestsFn } from '../../../../api/api';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function GuestSearchBar() {
  const [searchTerm, setSearchTerm] = useState('');
  const { basictoaster, setGuests } = useStateContext();

  useEffect(() => {
    const abortController = new AbortController();

    const delayDebounceFn = setTimeout(() => {
      // console.log(searchTerm);
      if (searchTerm !== '') {
        // get guests and filter the guests for searched term
        getGuestsFn(abortController.signal).then((guestsResp) => {
          if (!abortController.signal.aborted) {
            // if the response is not error filter the guests for the searched term
            if (!containsError(guestsResp) && !isObject(guestsResp)) {
              const filteredData = guestsResp.filter(item => 
                item.name.toLowerCase().startsWith(searchTerm.toLowerCase())
              );
              setGuests(filteredData);
            }
          }
        }).catch((err) => {basictoaster("error", "Errore ospiti", err, () => {})});
      } else {
        // reset the guests
        getGuestsFn(abortController.signal).then((guestsResp) => {
          if (!abortController.signal.aborted) {
            setGuests(guestsResp);
          }
        }).catch((err) => {basictoaster("error", "Errore ospiti", err, () => {})});
      }
    }, 700);

    return () => { clearTimeout(delayDebounceFn); abortController.abort(); }
  }, [basictoaster, searchTerm, setGuests]);

  return (
    <Grid container spacing={2}>
      <Grid xs={4} />
      <Grid xs={4}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={'Nome ...'}
            inputProps={{ 'aria-label': 'search' }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Search>
      </Grid>
      <Grid xs={4} />
    </Grid>
  );
}
