/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useStateContext } from '../../../contexts/ContextProvider';
import '../../../App.css'
import { Spinner } from '../../../AbstractElements';
import Grow from '@mui/material/Grow';
import { getNewsFn } from '../../../api/api';
import NewsCards from './NewsCards/NewsCards';
import AddNews from './AddNews';

function containsRolesDb(item) {
  return Object.prototype.hasOwnProperty.call(item, 'roles_db');
}
function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

const News = () => {
  const { basictoaster, loading, setLoading, news, setNews } = useStateContext();
  // get user db roles
  const [usrDbRoles, setUsrDbRoles] = useState([]);

  useEffect(() => {
    // set useer db role
    let userData = localStorage.getItem("user_data");
    if (userData !== null) {
      let userDataObj = JSON.parse(userData);
      if (containsRolesDb(userDataObj)) {
        setUsrDbRoles(userDataObj.roles_db);
      }
    }
    // set ab to state and use this new ab in this code
    let newAbortController = new AbortController()
    setLoading(true);
    // if is from kiosk show only the last 10
    getNewsFn('all', newAbortController.signal).then((newsResp) => {
      if (!newAbortController.signal.aborted) {
        setNews(newsResp);
        setLoading(false);
      }
    }).catch((err) => {basictoaster("error", "Errore notizie", err, () => {})});
    
    // Cleanup function to abort the request when the component unmounts
    return () => {
      newAbortController.abort();
    };
  }, [])

  // ALERT
  useEffect(() => {
    if (isObject(news) && containsError(news)) {
      basictoaster("error", "Errore notizie", news.Error, setNews)
    }
  }, [news])

  return (
    <Fragment>
    <br />
      {/* FROMO KIOSK HIDE BREADCRUMBS */}
      <Breadcrumbs phish mainTitle="Notizie" parent="DomusVestra" title="Notizie" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Grow in={true}><div>
            <Card>
              <CardBody>
                {/* <NewReq /><br /><br /> */}
                {loading ? (
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-1' }} />
                  </div>
                ) : (
                  <>
                    <h2><b>Notizie</b></h2><br />
                    {/* SHOW ADD NEWS ONLY IF USER HAS ROLE PUB */}
                    {usrDbRoles.includes('PUB') && (
                      <><AddNews /><br /><br /></>
                    )}
                    <NewsCards />
                  </>
                )}
              </CardBody>
            </Card>
            </div></Grow>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default News;