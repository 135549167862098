/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { getGuestsMovementsFn, deleteGuestsMovementsFn } from '../../../api/api';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Trash2 } from 'react-feather';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}

function DeleteGuestMovements({ movId }) {
  const { basictoaster, setGuestsMovements, setExecuting } = useStateContext();

  const delMovH = () => {
    SweetAlert.fire({
        title: 'Sei sicuro?',
        text: 'Il tuo movimento ospite verrà cancellato',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            deleteGuestsMovementsFn(movId).then(async (response) => {
              if (response.Success) {
                  SweetAlert.fire( 
                    'Fatto!',
                    'Il tuo movimento ospite è stato cancellato.',
                    'success'
                  );
              } else if (containsError(response)) {
                SweetAlert.fire(
                  'Errore!',
                  `Errore: ${response.Error}.`,
                  'error'
                );
              }
              getGuestsMovementsFn('always').then((resp) => {   
                setGuestsMovements(resp);
              }).catch((err) => {basictoaster("error", "Errore movimenti ospiti", err, () => {})})
            });
          }
          setExecuting(false);
        }).catch((err) => {basictoaster("error", 'Errore eliminazione movimenti ospiti', err, () => {})});
  };

  return (
      <button className='imgButton' onClick={delMovH}><Trash2 width={20} /></button>
  );
}

export default DeleteGuestMovements;
