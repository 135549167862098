import React from 'react';
import { Suspense } from 'react';
import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { auth0 } from '../Config/Config';
import { Auth0Provider } from '@auth0/auth0-react';
import Loader from '../Layout/Loader';
import LayoutRoutes from '../Route/LayoutRoutes';
import Signin from '../Auth/Signin';
import CircleLoadModal from '../Components/Pages/CircleLoadModal/CircleLoadModal';
import { useStateContext } from '../contexts/ContextProvider';
import Home from '../Components/Pages/Home/Home';
import AppLayout from '../Layout/Layout';
import ResetPassword from '../Components/Pages/ResetPassword/ResetPassword';
import News from '../Components/Pages/News/News';
import Documents from '../Components/Pages/Documents/Documents';

const PrivateWrapper = () => (localStorage.getItem('logged') ? <Outlet /> : <Navigate to="/login" />);

const Routers = () => {
        const { executing, setExecuting } = useStateContext();
        return (
                <Auth0Provider domain={auth0.domain} clientId={auth0.clientId} redirectUri={auth0.redirectUri}>
                        <CircleLoadModal  executing={executing} closeExecuting={() => {setExecuting(false)}} />
                        <BrowserRouter basename={'/'}>
                                <>
                                        <Suspense fallback={<Loader />}>
                                                <Routes>
                                                        {/* LOGIN ROUTE */}
                                                        <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />
                                                        {/* RESET PASS ROUTE */}
                                                        <Route path={`/reset-password`} element={<ResetPassword />} />
                                                        {/* VISIBLE WHEN NOT LOGGED IN ROUTES */}
                                                        <Route element={<AppLayout />}>
                                                                {/* HOME ROUTE */}
                                                                <Route exact path={`/home`} element={<Home />}/>
                                                                {/* NEWS ROUTE */}
                                                                <Route exact path={`/news`} element={<News />}/>
                                                                {/* DOCUMENTS ROUTE */}
                                                                <Route exact path={`/documents`} element={<Documents />}/>
                                                        </Route>
                                                        <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/home`} />}/>
                                                        <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/home`} />} />
                                                        {/* HOME KIOSK ROUTE */}
                                                        <Route exact path={`/kiosk`} element={<Home kioskMode />}/>
                                                        {/* PRIVATE ROUTE ONLY LOGGED IN*/}
                                                        <Route element={<PrivateWrapper />}>
                                                                {/* ALL THE ROUTES MAP */}
                                                                <Route path={`/*`} element={<LayoutRoutes />} />
                                                        </Route>
                                                </Routes>
                                        </Suspense>
                                </>
                        </BrowserRouter>
                </Auth0Provider>
        );
};

export default Routers;